/* global reset using universal selector*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: black;
  background-color: #eeeeee;
}

